import { alpha, Box, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import { Icon, ReverseTheme, StatusTag } from '@front/ui';

import TooltipListItem from '../../components/TooltipListItem';
import { TooltipListItemType } from '../../components/TooltipListItem/types';
import TooltipTitle from '../../components/TooltipTitle';

import { DescriptionConfig, ListTooltipLayoutConfig, TagConfig } from './types';

const styles = {
  root: {
    width: '200px',
    p: 1,
    bgcolor: 'background.darker',
    color: 'text.primary',
    borderRadius: 1,
    display: 'grid',
    gap: 1,
  },
  properties: {
    display: 'grid',
    gap: 1,
  },
  description: {
    mt: 1,
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
    alignItems: 'center',
  },
  tag: {
    whiteSpace: 'nowrap',
    gap: 0.5,
    height: 18,
    px: 1,
    '& .MuiTypography-root': {
      typography: 'caption',
    },
    '& .status-tag-tooltip-inner': {
      display: 'inline-flex',
    },
  },
  tagBg: (color: 'yellow' | 'orange' | 'red' | 'green' | 'blue') => ({
    backgroundColor: (theme: Theme) =>
      alpha(theme.palette.highlight[color], 0.1),
  }),
};

function isTooltipListItem(
  item: TooltipListItemType | false
): item is TooltipListItemType {
  return item !== false;
}

function isTagItem(item: TagConfig | false): item is TagConfig {
  return item !== false;
}

type DescriptionProps = {
  description?: DescriptionConfig;
};

function Description({ description }: DescriptionProps) {
  if (!description) return null;

  const { content, sx } = description;
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.description, ...sxProps]}>
      <Typography variant="caption">{content}</Typography>
    </Box>
  );
}

type TagsProps = {
  tags?: (TagConfig | false)[];
};

function Tags({ tags = [] }: TagsProps) {
  const items = tags.filter(isTagItem);
  if (items.length === 0) return null;

  return (
    <Box sx={styles.tags}>
      {items.map((tag, i) => {
        const sxProps = Array.isArray(tag.sx) ? tag.sx : [tag.sx];
        return (
          <StatusTag
            key={`${tag.text}-${i}`}
            sx={[styles.tag, !!tag.tip && { zIndex: 2 }, ...sxProps]}
            icon={tag.icon && <Icon name={tag.icon} width={16} height={16} />}
            color={tag.color}
            name={tag.text}
            tip={tag.tip}
            onClick={(ev) => {
              ev.stopPropagation();
            }}
          />
        );
      })}
    </Box>
  );
}

type ListTooltipLayoutProps = Omit<ListTooltipLayoutConfig, 'layout'>;

export default function ListTooltipLayout({
  title,
  properties,
  description,
  tags,
}: ListTooltipLayoutProps) {
  return (
    <ReverseTheme>
      <Box sx={styles.root}>
        <TooltipTitle {...title} />
        <Description description={description} />
        <Box sx={styles.properties}>
          {properties.filter(isTooltipListItem).map((item, index) => (
            <TooltipListItem key={index} {...item} />
          ))}
        </Box>
        <Tags tags={tags} />
      </Box>
    </ReverseTheme>
  );
}
