import { useEffect, useRef, useState } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import { AnswerFormatType, PinnedQuestionMode } from '@lib/web/apis';

import usePracticeQuestion from './usePracticeQuestion';
import usePracticeQuestionStatus from './usePracticeQuestionStatus';

export default function useQuizPinQuestion() {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [viewMode, setViewMode] = useState<PinnedQuestionMode>(
    PinnedQuestionMode.Minimization
  );
  const { id, creatorQuestionInfo } = usePracticeQuestion();
  const { isSubmitted } = usePracticeQuestionStatus();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<HTMLDivElement>(null);
  const passageRef = useRef<HTMLDivElement>(null);

  const pinnedRef = useRef<HTMLDivElement>(null);
  const pinnedQuestionScrollbarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollbarTarget = scrollbarRef.current;

    if (scrollbarTarget) {
      scrollbarTarget.scrollTo({ top: 0 });
    }
  }, [id]);
  useEffect(() => {
    const wrapperTarget = wrapperRef.current;
    const passageTarget = passageRef.current;
    const scrollbarTarget = scrollbarRef.current;

    const handleScroll = () => {
      if (
        viewMode === PinnedQuestionMode.Minimization &&
        scrollbarTarget &&
        passageTarget &&
        wrapperTarget
      ) {
        const passageHeight = passageTarget.offsetHeight;
        const scrollbarHeight = scrollbarTarget.offsetHeight;
        const scrollbarScrollTop = scrollbarTarget.scrollTop;
        const isInViewport =
          scrollbarScrollTop + scrollbarHeight - passageHeight >= 0;

        if (isInViewport) {
          // if the question is in viewport => hide the accordion
          wrapperTarget.style.gridTemplateRows = '1fr 0';
        } else {
          wrapperTarget.style.gridTemplateRows = '1fr 40px';
        }
      }
    };

    if (scrollbarTarget && !lgUp) {
      scrollbarTarget.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollbarTarget && !lgUp)
        scrollbarTarget.removeEventListener('scroll', handleScroll);
    };
  }, [lgUp, viewMode]);

  useEffect(() => {
    // handle on view mode changed

    let contentHeight = 0;
    let defaultHeight = 40; // 40 = the accordion height

    if (viewMode !== PinnedQuestionMode.Minimization) {
      contentHeight = pinnedQuestionScrollbarRef.current?.scrollHeight || 0;
      if (
        !isSubmitted &&
        creatorQuestionInfo?.answerFormatType === AnswerFormatType.GridIn
      ) {
        defaultHeight += 115; // 115 = GridIn help text height
      }
    }

    const height = defaultHeight + contentHeight;
    const wrapperTarget = wrapperRef.current;
    if (wrapperTarget) {
      wrapperTarget.style.gridTemplateRows = `1fr min(100%, ${height}px)`;
    }
  }, [viewMode, isSubmitted, creatorQuestionInfo?.answerFormatType]);

  useEffect(() => {
    // handle on question changed

    setViewMode(PinnedQuestionMode.Minimization);

    const wrapperTarget = wrapperRef.current;
    const scrollbarTarget = scrollbarRef.current;
    const passageTarget = passageRef.current;

    if (passageTarget && scrollbarTarget && wrapperTarget && !lgUp) {
      const wrapperHeight = wrapperTarget.clientHeight;
      const passageHeight = passageTarget.offsetHeight;
      // check if the passage is in the wrapper
      const isShortQuestion = wrapperHeight >= passageHeight;

      const scrollbarHeight = scrollbarTarget.offsetHeight;
      const scrollbarScrollTop = scrollbarTarget.scrollTop;
      const isInViewport =
        scrollbarScrollTop + scrollbarHeight - passageHeight >= 0;

      if (isShortQuestion || isInViewport) {
        wrapperTarget.style.gridTemplateRows = '1fr 0';
      } else {
        wrapperTarget.style.gridTemplateRows = '1fr 40px';
      }
    }
  }, [lgUp, id]);

  return {
    viewMode,
    setViewMode,
    wrapperRef,
    scrollbarRef,
    passageRef,
    pinnedRef,
    pinnedQuestionScrollbarRef,
  };
}
