import Typography from '@mui/material/Typography';
import { ParsedUrlQuery } from 'querystring';

import {
  AgentBreadcrumb,
  AwardCategoryBreadcrumb,
  ChallengeBreadcrumb,
  ClubBreadcrumb,
  ClubGroupBreadcrumb,
  CreatorPlaylistBreadcrumb,
  CustomDateBreadcrumb,
  InvitePhoneNumberBreadcrumb,
  PlaylistBreadcrumb,
  ReviewQuestionDetailBreadcrumb,
  ThreadViewBreadcrumb,
} from './BreadcrumbItems';

export const getAllBreadcrumbsValues = (
  pathname: string,
  query: ParsedUrlQuery,
  t: any
) => {
  return {
    home: {
      text: t('breadcrumb::Clubs'),
      icon: 'MainApps',
      href: '/',
    },
    agents: {
      text: t('breadcrumb::Agents'),
      icon: 'ProfileClubAgent',
      href: `/agents`,
    },
    agentDetail: {
      customizeComponent: (
        <AgentBreadcrumb agentId={(query.agentId || '') as string} />
      ),
    },
    clubAgents: {
      text: t('breadcrumb::Club Agents'),
      icon: 'ProfileClubAgent',
      href: `/club/${query.slug}/agents`,
    },
    clubAgentDetail: {
      customizeComponent: (
        <AgentBreadcrumb agentId={(query.agentId || '') as string} />
      ),
    },
    myAgents: {
      text: t('breadcrumb::My Agents'),
      icon: 'ProfileMyAgent',
      href: `/club/${query.slug}/my-agents`,
    },
    club: {
      customizeComponent: (
        <ClubBreadcrumb slug={(query.slug || '') as string} />
      ),
    },
    myCreatedClubs: {
      text: t('breadcrumb::My Created Clubs'),
      icon: 'PropertyTypePerson',
      href: '/club/my-created',
    },
    joinedClubs: {
      text: t('breadcrumb::Joined Clubs'),
      icon: 'TextEditorCheck',
      href: '/club/joined',
    },
    clubGroup: {
      text: t('breadcrumb::Club Groups'),
      icon: 'MainClubGroup',
      href: '/club-group/',
    },
    clubGroupDetail: {
      customizeComponent: (
        <ClubGroupBreadcrumb groupId={(query.groupId || '') as string} />
      ),
      href: `/club-group/${query.groupId}`,
    },
    goals: {
      text: t('breadcrumb::goal.Goal Rings'),
      icon: 'MainSetGoal',
      href: `/club/${query.slug}/analytics/goals`,
    },
    history: {
      text: t('breadcrumb::History'),
      icon: 'TestHistory',
      href: `/club/${query.slug}/playlists/history`,
    },
    creators: {
      text: t('breadcrumb::Creators'),
      icon: 'TestWriting',
      href: `/club/${query.slug}/playlists/creators`,
    },
    playlistsMistakes: {
      text: query.title || t('breadcrumb::Mistakes'),
      icon: 'OtherWarning',
      href: `/club/${query.slug}/playlists/mistakes/${query.markId}`,
    },
    playlistsOvertime: {
      text: query.title || t('breadcrumb::Overtime'),
      icon: 'TestClock',
      href: `/club/${query.slug}/playlists/overtime/${query.markId}`,
    },
    playlistsEmoji: {
      text: query.title ? '' : t('breadcrumb::Reaction'), // if query has title, only use icon to display this emoji
      icon: (
        <Typography variant="subtitle1" component="span">
          {query.title}
        </Typography>
      ),
      href: `/club/${query.slug}/playlists/emoji/${query.markId}`,
    },
    playlistsHashtags: {
      text: query.title || t('breadcrumb::Hashtags'),
      icon: 'ChatHashtag',
      href: `/club/${query.slug}/playlists/hashtags/${query.markId}`,
    },
    emoji: {
      text: t('breadcrumb::Reaction'),
      icon: 'NFTFavorite',
      href: `/club/${query.slug}/playlists/emoji`,
    },
    hashtags: {
      text: t('breadcrumb::Hashtags'),
      icon: 'ChatHashtag',
      href: `/club/${query.slug}/playlists/hashtags`,
    },
    saved: {
      text: t('breadcrumb::Saved Playlists'),
      icon: 'TestBookmarkFilled',
      href: `/club/${query.slug}/playlists/saved`,
    },
    friendsPlaylists: {
      text: t('breadcrumb::Followings'),
      icon: 'OtherFriendsPlaylist',
      href: `/club/${query.slug}/playlists/friends-playlists`,
    },
    friendsPlaylistsDetail: {
      text: query.title || t('breadcrumb::Followings'),
      icon: 'ProfilePlaylist',
      customizeComponent: (
        <PlaylistBreadcrumb quizId={(query.quizId || '') as string} />
      ),
    },
    clubNotifications: {
      text: t('breadcrumb::Notifications'),
      icon: 'MainNotificationOn',
      href: `/club/${query.slug}/notifications`,
    },
    notifications: {
      text: t('breadcrumb::Notifications'),
      icon: 'MainNotificationOn',
      href: `/notifications`,
    },
    directMessages: {
      text: t('breadcrumb::DirectMessages.Entry'),
      icon: 'ThreadsDirectMessages',
      href: `/direct-messages`,
    },
    directMessagesNewThread: {
      text: t('breadcrumb::DirectMessages.New Thread'),
      icon: 'TestAdd',
      href: `/direct-messages/new-thread`,
    },
    directMessagesView: {
      customizeComponent: (
        <ThreadViewBreadcrumb viewId={(query.viewId || '') as string} />
      ),
    },
    start: {
      text: t('breadcrumb::Start', 'Start Practice'),
      icon: 'OtherPlay',
      href: `/club/${query.slug}/start`,
    },
    playlist: {
      customizeComponent: (
        <PlaylistBreadcrumb quizId={(query.quizId || '') as string} />
      ),
      // XXX: use a correct way to build query string,
      // (currently we don't need this href because the opened page doesn't need to be clicked)
      // href: `/club/${query.slug}/playlist/${query.quizId}?variant=${query.variant}`
    },
    creatorsPlaylist: {
      customizeComponent: (
        <CreatorPlaylistBreadcrumb quizId={(query.quizId || '') as string} />
      ),
    },
    challenges: {
      text: t('breadcrumb::Challenges'),
      icon: 'MainChallenge',
      href: `/club/${query.slug}/challenges`,
    },
    challenge: {
      customizeComponent: (
        <ChallengeBreadcrumb quizId={(query.quizId || '') as string} />
      ),
      // XXX: use a correct way to build query string,
      // (currently we don't need this href because the opened page doesn't need to be clicked)
      // href: `/club/${query.slug}/challenge/${query.quizId}?status=${query.status}`
    },
    rankings: {
      text: t('breadcrumb::Rankings'),
      icon: 'TestTrophy',
      href: `/club/${query.slug}/rankings`,
    },
    analyticsMistakesWrong: {
      text: t('breadcrumb::Mistakes'),
      icon: 'OtherWarning',
      href: `/club/${query.slug}/analytics/mistakes/${query.category}`,
    },
    analyticsMistakesOvertime: {
      text: t('breadcrumb::Overtime'),
      icon: 'TestClock',
      href: `/club/${query.slug}/analytics/mistakes/${query.category}`,
    },
    analyticsWeaknessOutOfPractice: {
      text: t(
        'breadcrumb::Weakness Have Not Practiced Yet',
        'Weakness Haven’t Practiced Yet'
      ),
      icon: 'MenuThumbsDownOutline',
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsWeaknessOvertime: {
      text: t('breadcrumb::Weakness Overtime'),
      icon: 'TestClock',
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsWeaknessWrong: {
      text: t('breadcrumb::Weakness Wrong'),
      icon: 'OtherWarning',
      href: `/club/${query.slug}/analytics/weakness/${query.category}`,
    },
    analyticsAcceptance: {
      text: t('breadcrumb::Acceptance'),
      icon: 'AnalyticsAcceptance',
      href: `/club/${query.slug}/analytics/acceptance`,
    },
    analyticsMyPath: {
      text: t('breadcrumb::Compare Paths'),
      icon: 'AnalyticsMyPath',
      href: `/club/${query.slug}/analytics/my-path`,
    },
    summary: {
      text: t('breadcrumb::Summary'),
      icon: 'AnalyticsProgress',
      href: `/club/${query.slug}/summary`,
    },

    summaryPointsActivity: {
      text: t('breadcrumb::Wallet Activities'),
      icon: 'MainWallet',
      href: `/club/${query.slug}/summary/points-activity`,
    },

    summaryChallenges: {
      text: t('breadcrumb::Challenges'),
      icon: 'MainChallenge',
      href: `/club/${query.slug}/summary/challenges`,
    },

    summaryPlaylists: {
      text: t('breadcrumb::Playlists'),
      icon: 'ProfilePlaylist',
      href: `/club/${query.slug}/summary/playlists`,
    },

    customDate: {
      href: `/club/${query.slug}/summary?customDate=${query.customDate}`,
      customizeComponent: (
        <CustomDateBreadcrumb date={query.customDate as string} />
      ),
    },

    createQuestions: {
      text: t('breadcrumb::Create Questions'),
      icon: 'TestAdd',
      href: `/club/${query.slug}/create-questions`,
    },

    reviewQuestions: {
      text: t('breadcrumb::Review Questions'),
      icon: 'LoginSee',
      href: `/club/${query.slug}/review-questions`,
    },

    reviewAllQuestionsDetail: {
      text: t('breadcrumb::All Questions'),
      icon: 'ChatQuestionList',
      href: `/club/${query.slug}/review-questions/all`,
    },

    reviewQuestionsDetail: {
      customizeComponent: (
        <ReviewQuestionDetailBreadcrumb userId={query.userId as string} />
      ),
    },

    qBankReporter: {
      text: t('breadcrumb::QBank Reporter'),
      icon: 'TestReport',
      href: `/club/${query.slug}/qbank-reporter`,
    },

    marketplaceAvailable: {
      text: t('breadcrumb::Available'),
      icon: 'TextEditorCheck',
      href: '/marketplace/available',
    },

    marketplaceUnavailable: {
      text: t('breadcrumb::Unavailable'),
      icon: 'ActionCloseSmall',
      href: '/marketplace/unavailable',
    },

    marketplaceComingSoon: {
      text: t('breadcrumb::Coming Soon'),
      icon: 'TestClockSolid',
      href: '/marketplace/coming-soon',
    },

    marketplaceMyFavorite: {
      text: t('breadcrumb::My Favorite'),
      icon: 'NFTFavoriteSolid',
      href: '/marketplace/my-favorite',
    },

    marketplaceMyAvatars: {
      text: t('breadcrumb::My Avatars'),
      icon: 'ProfileNFTs',
      href: '/marketplace/my-avatars',
    },

    account: {
      text: t('breadcrumb::My Profile'),
      icon: 'MainProfileSolid',
      href: '/profile/account',
    },

    accountEdit: {
      text: t('breadcrumb::Edit Profile'),
      icon: 'OtherEdit',
      href: '/profile/account/edit',
    },

    discountCode: {
      text: t('breadcrumb::Discount Code'),
      icon: 'OtherDiscountNoOuter',
      href: '/profile/discount-code',
    },

    discountCodeManagement: {
      text: t('breadcrumb::Manage Discount Codes'),
      icon: 'PropertyTypeTag',
      href: '/profile/discount-code',
    },

    profileAwards: {
      text: t('breadcrumb::Awards'),
      icon: 'ProfileAwards',
      href: '/profile/awards',
    },

    profileAwardsAlmostThere: {
      text: t('breadcrumb::Almost There'),
      icon: 'ProfileAwards',
      href: '/profile/awards/almost-there',
    },

    profileAwardsByCategory: {
      icon: 'ProfileAwards',
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },

    myPlan: {
      text: t('breadcrumb::My Plan'),
      icon: 'ProfileMyPlan',
      href: '/profile/my-plan',
    },

    parentPhoneNumber: {
      customizeComponent: <InvitePhoneNumberBreadcrumb />,
      href: '/profile/parent-phone-number',
    },

    inviteFriends: {
      text: t('breadcrumb::Invite Friends'),
      icon: 'OtherAddFriend',
      href: '/profile/invite-friends',
    },

    myAvatars: {
      text: t('breadcrumb::My Avatars'),
      icon: 'ProfileNFTs',
      href: '/profile/my-avatars',
    },

    settings: {
      text: t('breadcrumb::Settings'),
      icon: 'ProfileSetting',
      href: '/profile/settings',
    },

    wallet: {
      text: t('breadcrumb::Wallet'),
      icon: 'MainWallet',
      href: '/profile/wallet',
    },

    walletAhaPoints: {
      text: t('breadcrumb::Aha Points'),
      icon: 'OtherAhaPoints',
      href: '/profile/wallet/aha-points',
    },
    extendTrial: {
      text: t(
        'profile::invite.friends.paywall.title',
        'Invite a Friend, Extend Your Free Trial and Get Rewards'
      ),
      icon: 'OtherAddFriend',
      href: '/extend-trial',
    },

    practiceResult: {
      text: t('breadcrumb::Results'),
      icon: 'TestScoreTally',
      href: `/club/${query.slug}/practice/${query.quizId}/${query.roundNo}/result`,
    },
    practiceResultAwards: {
      text: t('breadcrumb::Awards'),
      icon: 'ProfileAwards',
      href: `/club/${query.slug}/practice/${query.quizId}/${query.roundNo}/result/awards`,
    },
    practiceResultAwardsByCategory: {
      icon: 'ProfileAwards',
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },
    examResult: {
      text: t('breadcrumb::Results'),
      icon: 'TestScoreTally',
      href: `/club/${query.slug}/exam/${query.quizId}/${query.roundNo}/result`,
    },
    examResultAwards: {
      text: t('breadcrumb::Awards'),
      icon: 'ProfileAwards',
      href: `/club/${query.slug}/exam/${query.quizId}/${query.roundNo}/result/awards`,
    },
    examResultAwardsByCategory: {
      icon: 'ProfileAwards',
      customizeComponent: (
        <AwardCategoryBreadcrumb
          categoryPath={(query.categoryPath || '') as string}
        />
      ),
    },
  };
};
