import { useEffect, useState } from 'react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { apis, DeductQuizQuotaType } from '@lib/web/apis';
import { callWithToast } from '@lib/web/utils';

/**
 * This hook determines if the user can view the solution to a quiz question.
 * We use 'quizQuestionId' as the key, allowing the back-end to identify whether it is the same solution.
 *
 * - If a solution is viewed again within the same quiz, the quota is not deducted.
 * - If a solution is viewed again in a different quiz round, the quota will be deducted.
 *
 * Note: The 'quizQuestionId' is not shared between the web and the qbank.
 * Therefore, if the same solution is viewed on both the web and qbank, the quota will be deducted twice.
 */
export default function useSolutionViewableStatus({
  quizQuestionId,
}: {
  quizQuestionId?: string;
}) {
  const { user } = useUser();

  const [status, setStatus] = useState<
    'init' | 'unauthorized' | 'exceed-quota' | 'viewable' | 'error'
  >(user ? 'init' : 'unauthorized');

  useEffect(() => {
    const checkQuota = async () => {
      if (!quizQuestionId) return;

      const [res] = await callWithToast(
        apis.quiz.deductQuizQuota({
          type: DeductQuizQuotaType.QuizSolutionDaily,
          referenceIds: [quizQuestionId],
        }),
        { showLoading: false }
      );
      setStatus(
        !res ? 'error' : res.data.isSuccess ? 'viewable' : 'exceed-quota'
      );
    };

    if (status === 'init') {
      void checkQuota();
    }
  }, [quizQuestionId, status]);

  return { solutionViewableStatus: status };
}
