import React, { FocusEvent, ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';
import { alpha, Theme } from '@mui/material/styles';

import { PropertyType } from '../../types/enums';
import getPropertyIcon from '../../utils/property';
import TextFieldLabel from '../TextFieldLabel';

import { TextFieldSizeType } from './types';

type FixedTextProps = {
  text?: ReactNode;
  isPrefix?: boolean;
};

type InputRenderProps = {
  disabled: InputBaseProps['disabled'];
  onBlur: InputBaseProps['onBlur'];
  onFocus: InputBaseProps['onFocus'];
};
export type TextFieldProps = Omit<
  InputBaseProps,
  'sx' | 'prefix' | 'suffix' | 'size'
> & {
  label?: ReactNode;
  labelIcon?: PropertyType | ReactNode;
  labelTooltip?: string;
  labelSuffix?: ReactNode;
  requiredMark?: boolean;
  errorIcon?: ReactNode;
  note?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  helperText?: ReactNode;
  focused?: boolean;
  success?: boolean;
  sx?: BoxProps['sx'];
  inputSx?: InputBaseProps['sx'];
  activeText?: ReactNode;
  renderInput?: (ev: InputRenderProps) => ReactNode;
  onClick?: BoxProps['onClick'];
  size?: TextFieldSizeType;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  main: {
    position: 'relative',
    display: 'flex',
    borderRadius: 1,
    border: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    outlineColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.light, 0.3)
        : alpha(theme.palette.primary.dark, 0.3),
    outlineStyle: 'solid',
    outlineWidth: 0,
  },
  hover: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .textfield-main': {
          border: (theme: Theme) =>
            `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
        },
      },
    },
  },
  input: {
    flex: 1,
    height: '100%',
    typography: 'body1',
    '& input, & textarea': {
      px: '8px',
      py: '10px',
      '&::placeholder': {
        color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
        opacity: 1,
      },
    },
  },
  fixedText: {
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    '&.textfield-prefix': { pl: '10px', typography: 'subtitle2' },
    '&.textfield-suffix': { pr: '10px', opacity: 0.5, typography: 'caption' },
  },
  otherMessage: {
    typography: 'caption',
    minHeight: 16,
    lineHeight: '16px',
    color: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? alpha(theme.palette.text.primary, 0.64)
        : alpha(theme.palette.text.primary, 0.5),
  },
  disabled: {
    '& .textfield-main': {
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      border: '1px solid transparent',
    },
  },
  focused: {
    '& .textfield-main': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.primary.light, 0.6)
          : alpha(theme.palette.primary.dark, 0.6),
      outlineWidth: '2px',
    },
  },
  error: {
    '& .textfield-main': {
      borderColor: 'error.dark',
      outlineColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.3),
    },
    '& .textfield-message': {
      color: 'error.dark',
    },
  },
  success: {
    '& .textfield-main': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
      outlineColor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.success.light, 0.3)
          : alpha(theme.palette.success.dark, 0.3),
    },
  },
};

const getSize = (size?: TextFieldSizeType) => {
  if (size === 'rwd') {
    return {
      '& input, & textarea': {
        px: { xs: '8px', md: '10px' },
        py: { xs: '10px', md: '4px' },
        fontSize: { xs: 16, md: 14 },
        minHeight: 22,
      },
    };
  }
  if (size === 'sm') {
    return {
      '& input, & textarea': {
        px: '10px',
        py: '4px',
        fontSize: 14,
        minHeight: 22,
      },

      '& .MuiAutocomplete-endAdornment': {
        display: 'flex',
        gap: 0.5,
        button: { p: '1px' },
        svg: { width: 16, height: 16 },
      },
    };
  }
  return {
    '& input, & textarea': {
      px: '8px',
      py: '10px',
    },
  };
};

function FixedText({ text, isPrefix }: FixedTextProps) {
  if (!text) return null;

  return (
    <Box
      className={`textfield-fixed ${
        isPrefix ? 'textfield-prefix' : 'textfield-suffix'
      }`}
      sx={styles.fixedText}
    >
      {text}
    </Box>
  );
}

export const OtherMessage = ({
  inputFocused,
  activeText,
  note,
  error,
  helperText,
}: {
  inputFocused: boolean;
  error: boolean;
  helperText: ReactNode;
  activeText?: ReactNode;
  note?: ReactNode;
}) => {
  if (inputFocused && activeText && !helperText) {
    return (
      <Box sx={styles.otherMessage} className="textfield-message">
        {activeText}
      </Box>
    );
  }

  if ((!!note && !error) || !!helperText) {
    return (
      <Box sx={styles.otherMessage} className="textfield-message">
        {!!note && !error && note}
        {!!helperText && helperText}
      </Box>
    );
  }

  return <Box sx={styles.otherMessage} className="textfield-message" />;
};

const TextField = React.forwardRef(
  (
    {
      label,
      labelIcon,
      labelTooltip,
      labelSuffix,
      errorIcon,
      requiredMark,
      prefix,
      suffix,
      note,
      helperText,
      focused = false,
      error = false,
      success = false,
      disabled = false,
      sx,
      inputSx,
      activeText,
      onBlur,
      onFocus,
      onClick,
      renderInput,
      size = 'rwd',
      ...rest
    }: TextFieldProps,
    ref
  ) => {
    const [active, setActive] = useState(false);
    const handleBlur = (
      ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      setActive(false);
      onBlur?.(ev);
    };

    const handleFocus = (
      ev: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
    ) => {
      setActive(true);
      onFocus?.(ev);
    };

    const inputFocused = focused || active;
    const sxProps = Array.isArray(sx) ? sx : [sx];
    const inputSxProps = Array.isArray(inputSx) ? inputSx : [inputSx];
    return (
      <Box
        sx={[
          styles.root,
          !disabled && !error && !success && !inputFocused && styles.hover,
          disabled && styles.disabled,
          inputFocused && styles.focused,
          error && styles.error,
          success && styles.success,
          ...sxProps,
        ]}
        ref={ref}
        onClick={onClick}
      >
        {label && (
          <TextFieldLabel
            disabled={disabled}
            icon={getPropertyIcon(labelIcon)}
            required={requiredMark}
            suffix={labelSuffix}
            labelTooltip={labelTooltip}
            suffixIcon={error && errorIcon}
          >
            {label}
          </TextFieldLabel>
        )}
        <Box sx={styles.main} className="textfield-main">
          <FixedText text={prefix} isPrefix />
          {renderInput ? (
            renderInput({
              onBlur: handleBlur,
              onFocus: handleFocus,
              disabled,
            })
          ) : (
            <InputBase
              sx={[styles.input, getSize(size), ...inputSxProps]}
              onBlur={handleBlur}
              onFocus={handleFocus}
              disabled={disabled}
              {...rest}
            />
          )}
          <FixedText text={suffix} />
        </Box>
        <OtherMessage
          inputFocused={inputFocused}
          activeText={activeText}
          note={note}
          error={error}
          helperText={helperText}
        />
      </Box>
    );
  }
);

export default TextField;
