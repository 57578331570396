import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { isString } from '@front/helper';
import { BreadcrumbsValue } from '@front/ui';
import { useShortIdPool } from '@lib/web/hooks';

import { getAllBreadcrumbsValues } from './breadcrumbsValues';

export const getBreadcrumbsValuesByRoute = (
  pathname: string,
  query: any,
  t: any
): BreadcrumbsValue[] => {
  const values = getAllBreadcrumbsValues(pathname, query, t);
  const clearPathname = pathname.replace('/shared/[sid]', '');
  if (clearPathname === '/') {
    return [values.home];
  }

  if (clearPathname === '/agents') {
    return [values.agents];
  }

  if (clearPathname === '/agents/[agentId]') {
    return [values.agents, values.agentDetail];
  }

  if (clearPathname === '/club/[slug]/agents') {
    return [values.clubAgents];
  }

  if (clearPathname === '/club/[slug]/agents/[agentId]') {
    return [values.clubAgents, values.clubAgentDetail];
  }

  if (clearPathname === '/club/[slug]/agents/my-agents') {
    return [values.myAgents];
  }

  if (clearPathname === '/club/[slug]/rankings') {
    return [values.rankings];
  }

  if (clearPathname === '/club/[slug]') {
    return [values.home, values.club];
  }

  if (clearPathname === '/club/my-created') {
    return [values.myCreatedClubs];
  }

  if (clearPathname === '/club/joined') {
    return [values.joinedClubs];
  }

  if (clearPathname === '/club-group') {
    return [values.clubGroup];
  }

  if (clearPathname === '/club-group/[groupId]') {
    return [values.clubGroup, values.clubGroupDetail];
  }

  if (pathname.includes('/analytics/goals')) {
    return [values.goals];
  }

  if (pathname.includes('/analytics/mistakes/[category]')) {
    if (query.category === 'overtime') {
      return [values.analyticsMistakesOvertime];
    }
    if (query.category === 'total-wrong') {
      return [values.analyticsMistakesWrong];
    }
  }
  if (pathname.includes('/analytics/weakness/[category]')) {
    if (query.category === 'overtime') {
      return [values.analyticsWeaknessOvertime];
    }
    if (query.category === 'out-of-practice') {
      return [values.analyticsWeaknessOutOfPractice];
    }
    if (query.category === 'total-wrong') {
      return [values.analyticsWeaknessWrong];
    }
  }
  if (pathname.includes('/analytics/acceptance')) {
    return [values.analyticsAcceptance];
  }
  if (pathname.includes('/analytics/my-path')) {
    return [values.analyticsMyPath];
  }

  if (clearPathname === '/club/[slug]/summary') {
    if (query.customDate) {
      return [values.summary, values.customDate];
    }
    return [values.summary];
  }

  if (clearPathname === '/club/[slug]/summary/points-activity') {
    if (query.customDate) {
      return [values.summary, values.customDate, values.summaryPointsActivity];
    }
    return [values.summary, values.summaryPointsActivity];
  }

  if (clearPathname === '/club/[slug]/summary/challenges') {
    if (query.customDate) {
      return [values.summary, values.customDate, values.summaryChallenges];
    }
    return [values.summary, values.summaryChallenges];
  }

  if (clearPathname === '/club/[slug]/summary/playlists') {
    if (query.customDate) {
      return [values.summary, values.customDate, values.summaryPlaylists];
    }
    return [values.summary, values.summaryPlaylists];
  }

  if (pathname.includes('/club/[slug]/playlists/[group]')) {
    if (query.group === 'mistakes') {
      return [values.history, values.playlistsMistakes];
    }
    if (query.group === 'overtime') {
      return [values.history, values.playlistsOvertime];
    }
    if (query.group === 'emoji') {
      return [values.emoji, values.playlistsEmoji];
    }
    if (query.group === 'hashtags') {
      return [values.hashtags, values.playlistsHashtags];
    }
  }

  if (clearPathname === '/club/[slug]/playlists/creators/[quizId]') {
    return [values.creators, values.creatorsPlaylist];
  }

  if (clearPathname === '/club/[slug]/playlist/[quizId]') {
    // some playlists come from other pages and don't pass a variant value
    if (query.variant === 'default' || !query.variant) {
      return query.sid ? [values.playlist] : [values.history, values.playlist];
    }

    if (query.variant === 'saved') {
      return [values.saved, values.playlist];
    }

    if (query.variant === 'friends-playlists') {
      return [values.friendsPlaylists, values.friendsPlaylistsDetail];
    }
  }

  if (clearPathname === '/club/[slug]/challenge/[quizId]') {
    const statusCategory = Number.parseInt(query.status, 10);
    if (query.variant === 'saved') {
      return [values.saved, values.challenge];
    }

    if (statusCategory) {
      return [values.challenges, values.challenge];
    }

    return [values.challenge];
  }

  if (clearPathname === '/club/[slug]/challenges') {
    return [values.challenges];
  }

  if (clearPathname === '/club/[slug]/playlists/emoji') {
    return [values.emoji];
  }
  if (clearPathname === '/club/[slug]/playlists/hashtags') {
    return [values.hashtags];
  }
  if (clearPathname === '/club/[slug]/playlists/history') {
    return [values.history];
  }
  if (clearPathname === '/club/[slug]/playlists/creators') {
    return [values.creators];
  }
  if (clearPathname === '/club/[slug]/playlists/saved') {
    return [values.saved];
  }
  if (clearPathname === '/club/[slug]/playlists/friends-playlists') {
    return [values.friendsPlaylists];
  }

  if (clearPathname === '/club/[slug]/notifications') {
    return [values.clubNotifications];
  }

  if (clearPathname === '/club/[slug]/start') {
    return [values.start];
  }

  if (clearPathname === '/club/[slug]/create-questions') {
    return [values.createQuestions];
  }

  if (clearPathname === '/club/[slug]/review-questions') {
    return [values.reviewQuestions];
  }

  if (clearPathname === '/club/[slug]/review-questions/all') {
    return [values.reviewQuestions, values.reviewAllQuestionsDetail];
  }

  if (clearPathname === '/club/[slug]/review-questions/[userId]') {
    return [values.reviewQuestions, values.reviewQuestionsDetail];
  }

  if (clearPathname === '/club/[slug]/qbank-reporter') {
    return [values.qBankReporter];
  }

  if (clearPathname === '/marketplace/[status]') {
    if (query.status === 'available') {
      return [values.marketplaceAvailable];
    }
    if (query.status === 'unavailable') {
      return [values.marketplaceUnavailable];
    }
    if (query.status === 'coming-soon') {
      return [values.marketplaceComingSoon];
    }
    if (query.status === 'my-favorite') {
      return [values.marketplaceMyFavorite];
    }
    if (query.status === 'my-avatars') {
      return [values.marketplaceMyAvatars];
    }
  }

  if (clearPathname === '/profile/account') {
    return [values.account];
  }

  if (clearPathname === '/profile/account/edit') {
    return [values.account, values.accountEdit];
  }

  if (clearPathname === '/profile/discount-code') {
    return [values.discountCode];
  }

  if (clearPathname === '/profile/discount-code/management') {
    return [values.discountCodeManagement];
  }

  if (clearPathname === '/profile/awards') {
    return [values.profileAwards];
  }

  if (clearPathname === '/profile/awards/almost-there') {
    return [values.profileAwards, values.profileAwardsAlmostThere];
  }

  if (clearPathname === '/profile/awards/[categoryPath]') {
    return [values.profileAwards, values.profileAwardsByCategory];
  }

  if (clearPathname === '/profile/my-plan') {
    return [values.myPlan];
  }

  if (clearPathname === '/profile/parent-phone-number') {
    return [values.parentPhoneNumber];
  }

  if (clearPathname === '/profile/invite-friends') {
    return [values.inviteFriends];
  }

  if (clearPathname === '/profile/my-avatars') {
    return [values.myAvatars];
  }

  if (clearPathname === '/profile/settings') {
    return [values.settings];
  }

  if (clearPathname === '/profile/wallet') {
    return [values.wallet];
  }

  if (clearPathname === '/profile/wallet/aha-points') {
    return [values.wallet, values.walletAhaPoints];
  }

  if (clearPathname === '/notifications') {
    return [values.notifications];
  }

  if (clearPathname === '/direct-messages') {
    return [values.directMessages];
  }

  if (clearPathname === '/direct-messages/new-thread') {
    return [values.directMessagesNewThread];
  }

  if (clearPathname === '/direct-messages/view/[viewId]') {
    return [values.directMessagesView];
  }

  if (clearPathname === '/extend-trial') {
    return [values.extendTrial];
  }

  if (clearPathname === '/club/[slug]/practice/[quizId]/[roundNo]/result') {
    return [values.practiceResult];
  }

  if (
    clearPathname === '/club/[slug]/practice/[quizId]/[roundNo]/result/awards'
  ) {
    return [values.practiceResult, values.practiceResultAwards];
  }

  if (
    pathname ===
    '/club/[slug]/practice/[quizId]/[roundNo]/result/awards/[categoryPath]'
  ) {
    return [
      values.practiceResult,
      values.practiceResultAwards,
      values.practiceResultAwardsByCategory,
    ];
  }

  if (clearPathname === '/club/[slug]/exam/[quizId]/[roundNo]/result') {
    return [values.examResult];
  }

  if (clearPathname === '/club/[slug]/exam/[quizId]/[roundNo]/result/awards') {
    return [values.examResult, values.examResultAwards];
  }

  if (
    pathname ===
    '/club/[slug]/exam/[quizId]/[roundNo]/result/awards/[categoryPath]'
  ) {
    return [
      values.examResult,
      values.examResultAwards,
      values.examResultAwardsByCategory,
    ];
  }

  return [];
};

export default function useRouteBreadcrumbsValues(): BreadcrumbsValue[] {
  const { pathname, query } = useRouter();
  const { getQuizFullId, getMarkFullId } = useShortIdPool();
  const { t } = useTranslation();

  const updatedQuery = useMemo(() => {
    return {
      ...query,
      quizId: isString(query.quizId)
        ? getQuizFullId(query.quizId)
        : query.quizId,
      markId:
        isString(query.markId) && isString(query.group)
          ? getMarkFullId(query.group, query.markId)
          : query.markId,
    };
  }, [getMarkFullId, getQuizFullId, query]);

  return getBreadcrumbsValuesByRoute(pathname, updatedQuery, t);
}
