import { useRouter } from 'next/router';
import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { ClubViewSlug, SimilarClubViewSlug } from '../../types/enums';
import {
  generateHook,
  generateInfiniteHook,
  SwrHelperParams,
  useAuth,
} from '../query';

export type GetIaClubResMap = {
  [ClubViewSlug.CardCenterDefault]: GetIaClubCardCenterDefaultViewRes;
  [ClubViewSlug.CardCenterQBank]: GetIaClubCardCenterQBankViewRes;
  [ClubViewSlug.IconRhsCreatorQuestionDuplicate]: GetIaClubIconRhsCreatorQuestionDuplicateViewRes;
  [ClubViewSlug.IconRhsDefault]: GetIaClubIconRhsDefaultViewRes;
  [ClubViewSlug.ProfileCenterDefault]: GetIaClubProfileCenterDefaultViewRes;
  [ClubViewSlug.IconRhsClubList]: GetIaClubIconRhsClubListViewRes;
  [ClubViewSlug.IconRhsAdded]: GetIaClubIconRhsAddedViewRes;
};

export function useIaClubs<
  K = GetIaClubDefaultRes,
  T extends ClubViewSlug | undefined = undefined
>(query: IaSearchReq<T> = {}, { ...config }: SwrHelperParams = {}) {
  const router = useRouter();
  const isGuest = router.query.target === 'guest';
  const sid = (router.query.sid || router.query.shortId) as string;
  const { isLoading, isLogged } = useAuth();
  return generateHook<T extends ClubViewSlug ? GetIaClubResMap[T] : K>(
    config.enable === false || !router.isReady || isLoading
      ? ''
      : `v2/ia/clubs?${getIaQueryString(query)}`,
    {
      paginate: true,
      auth: !sid && !isGuest && !isLogged,
      errorRetryCount: 0,
      ...config,
    }
  );
}

export function useIaClubsList<
  K = GetIaClubResMap,
  T extends ClubViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
) {
  const limit = query.limit || 0;
  let filter: string | undefined = query.filter;

  if (query.viewSlug === ClubViewSlug.CardCenterDefault) {
    filter = 'totalQuestionCount;onlineMemberCount;createdAt';
  }

  return generateInfiniteHook<T extends ClubViewSlug ? GetIaClubResMap[T] : K>(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/clubs?${getIaQueryString({
            ...query,
            filter,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: config.auth,
    }
  );
}

export type GetIaSimilarClubResMap = {
  [SimilarClubViewSlug.CardCenterDefault]: GetIaSimilarClubCardCenterDefaultViewRes;
  [SimilarClubViewSlug.IconLhsDefault]: GetIaSimilarClubIconLhsDefaultViewRes;
};

export function useIaSimilarClubsList<
  K = GetIaSimilarClubResMap,
  T extends SimilarClubViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
) {
  const limit = query.limit || 0;
  let filter: string | undefined = query.filter;

  if (query.viewSlug === SimilarClubViewSlug.CardCenterDefault) {
    filter = '';
  }

  return generateInfiniteHook<
    T extends SimilarClubViewSlug ? GetIaSimilarClubResMap[T] : K
  >(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/similar-clubs?${getIaQueryString({
            ...query,
            filter,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: config.auth,
    }
  );
}
