/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import type { Key, SWRConfiguration, SWRResponse } from 'swr';
import type {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
  SWRInfiniteResponse,
} from 'swr/infinite';

import { useImmutableRequest } from '../useImmutableRequest';
import { useInfiniteState } from '../useInfiniteState';
import { useRequest } from '../useRequest';
import { useRequestInfinite } from '../useRequestInfinite';

const baseConfig = {
  errorRetryInterval: 10000,
  errorRetryCount: 3,
};

type ExtendConfig<L, P> = {
  auth?: boolean;
  sid?: string;
  data?: any;
  baseURL?: string;
  immutable?: boolean;
  withPublicAccessKey?: boolean;
  publicAccessKey?: string;
  method?: 'GET' | 'POST';
  list?: L;
  paginate?: P;
};
export type SwrHelperConfig<P, L> = SWRConfiguration & ExtendConfig<L, P>;

export type SwrInfiniteHelperConfig<P, L> = SWRInfiniteConfiguration &
  ExtendConfig<L, P>;

export type SwrHelperParams = SWRConfiguration & {
  auth?: boolean;
  immutable?: boolean;
  method?: 'GET' | 'POST';
  enable?: boolean;
  sid?: string;
};

export type InfiniteHookResponse<T> = SWRInfiniteResponse<
  PageResponse<T>,
  any
> & {
  dataset: T[];
  totalCount: number;
  pageCount: number;
  isLoadingInitialData: boolean;
  isLoadingMore: boolean;
  isEmpty: boolean;
  isReachingEnd: boolean;
  isLoading: boolean;
  pageSize: number;
};

const getSWRFunction = (immutable = false) => {
  if (immutable) {
    return useImmutableRequest;
  }

  return useRequest;
};

/**
 * @param {Key} key url
 * @param {object} config SwrHelperConfig
 */
export function generateHook<
  T,
  P extends false = false,
  L extends false = false
>(
  key: Key,
  config?: SwrHelperConfig<P, L>
): SWRResponse<Response<T>, AxiosError<ResponseError, any>>;
export function generateHook<T, P extends false = false, L extends true = true>(
  key: Key,
  config?: SwrHelperConfig<P, L>
): SWRResponse<ListResponse<T>, AxiosError<ResponseError, any>>;
export function generateHook<T, P extends true = true, L extends false = false>(
  key: Key,
  config?: SwrHelperConfig<P, L>
): SWRResponse<PageResponse<T>, AxiosError<ResponseError, any>>;

export function generateHook<T, P extends true | false, L extends true | false>(
  key: Key,
  config: SwrHelperConfig<P, L> = {}
):
  | SWRResponse<PageResponse<T>, AxiosError<ResponseError, any>>
  | SWRResponse<ListResponse<T>, AxiosError<ResponseError, any>>
  | SWRResponse<Response<T>, AxiosError<ResponseError, any>> {
  const { immutable, list, paginate, ...restConfig } = config;
  const swrFunction = getSWRFunction(immutable);

  if (paginate) {
    return swrFunction<PageResponse<T>, ResponseError>(key, {
      ...baseConfig,
      ...restConfig,
    });
  }
  if (list) {
    return swrFunction<ListResponse<T>, ResponseError>(key, {
      ...baseConfig,
      ...restConfig,
    });
  }
  return swrFunction<Response<T>, ResponseError>(key, {
    ...baseConfig,
    ...restConfig,
  });
}

export function generateInfiniteHook<T>(
  getKey: SWRInfiniteKeyLoader,
  pageSize: number,
  config: SwrInfiniteHelperConfig<true, false> = {}
): InfiniteHookResponse<T> {
  const { immutable, paginate, ...restConfig } = config;
  const swrFunction = useRequestInfinite;
  const { data, size, mutate, setSize, error, isLoading, isValidating } =
    swrFunction<PageResponse<T>>(getKey, restConfig);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const infiniteState = useInfiniteState<T>({
    data,
    error,
    size,
    pageSize,
  });

  return {
    data,
    size,
    error,
    isLoading,
    isValidating,
    setSize,
    mutate,
    ...infiniteState,
  };
}

function dataToPageResponse<T>(
  data: T[],
  { pageCount = data.length, totalCount = data.length } = {}
): PageResponse<T> {
  return {
    data: {
      items: data,
      limit: 100000, // don't care
      page: 1,
      pageCount,
      totalCount,
    },
  } as PageResponse<T>;
}

export function generateMockInfiniteHook<T>(
  data: T[]
): InfiniteHookResponse<T> {
  return {
    dataset: data,
    totalCount: data.length,
    pageCount: 1,
    pageSize: 10,
    isLoadingInitialData: false,
    isLoadingMore: false,
    isEmpty: false,
    isReachingEnd: true,
    isLoading: false,
    isValidating: false,
    size: 1,
    setSize: async () => undefined,
    mutate: async () => undefined,
    error: null,
    data: [dataToPageResponse(data)],
  };
}

/**
 * This reference to https://swr.vercel.app/blog/swr-v2.en-US#optimistic-ui
 * use this tool because we have this unique cases which is not typical swr sample has
 * @param originMutate
 */
export function buildInfiniteHookMutate<T, Error = unknown>(
  originMutate:
    | SWRInfiniteResponse<PageResponse<T>, Error>['mutate']
    | SWRResponse<PageResponse<T>[], Error>['mutate']
) {
  return (
    data?: Promise<any>,
    opts?: {
      optimisticData?: T[];
      optimisticPageData?: {
        pageCount?: number;
        totalCount: number;
      };
    }
  ) => {
    const optimisticData = opts?.optimisticData
      ? [dataToPageResponse(opts.optimisticData, opts?.optimisticPageData)]
      : undefined;

    return originMutate(data, {
      optimisticData,
      populateCache: false, // don't need to use the response from the promise data
      revalidate: true, // need to get the updated response from server side
      rollbackOnError: true,
    });
  };
}

export function buildHookMutate<T, Error = unknown>(
  originMutate: SWRResponse<Response<T>, Error>['mutate']
) {
  const dataToResponse = (data: T | null) =>
    ({
      data,
    } as Response<T>);

  return (
    data?: Promise<any>,
    opts?: {
      optimisticData?: T | null;
    }
  ) => {
    if (data === undefined && opts === undefined) {
      return originMutate(); // this behavior is different from originMutate(undefined, undefined), so we need to handle this situation here
    }

    const optimisticData =
      opts?.optimisticData !== undefined
        ? dataToResponse(opts.optimisticData)
        : undefined;

    return originMutate(data, {
      optimisticData,
      populateCache: false, // don't need to use the response from the promise data
      revalidate: true, // need to get the updated response from server side
      rollbackOnError: true,
    });
  };
}

export default generateHook;
