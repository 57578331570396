import { useMemo } from 'react';
import { replaceVariables } from '@lib/web/editor';
import { QuizSolutionItem } from '@lib/web/practice';

export type DisplaySolutionQuestion = {
  questionDetails?: {
    id: string;
    linkedComponentId: string;
  }[];
  correctAnswerIds: string[];
  creatorQuestionInfo?: CreatorQuestionInfo;
};

type SolutionParams = {
  labels?: string[];
  question: DisplaySolutionQuestion;
  solutionMap: Record<string, string>;
};

export default function useDisplaySolutionItems({
  labels,
  question,
  solutionMap,
}: SolutionParams) {
  return useMemo(() => {
    if (!question.questionDetails?.length) return [];

    const correctResults: QuizSolutionItem[] = [];
    const incorrectResults: QuizSolutionItem[] = [];

    question.questionDetails.forEach((detail, index) => {
      const content = solutionMap[detail.linkedComponentId]; // for user create question, we store id inside content
      const isCorrect = question.correctAnswerIds.includes(detail.id);

      const current = isCorrect
        ? correctResults.find((r) => r.content === content)
        : incorrectResults.find((r) => r.content === content);

      if (current) {
        current.optionKeys.push(
          labels?.[index] || String.fromCharCode(65 + index)
        );
      } else {
        const item = {
          optionKeys: [
            labels?.[index] || `Choice ${String.fromCharCode(65 + index)}`,
          ],
          content: replaceVariables(
            content,
            question.creatorQuestionInfo?.materials
          ),
          isCorrect,
        };

        if (isCorrect) {
          correctResults.push(item);
        } else {
          incorrectResults.push(item);
        }
      }
    });
    return [...correctResults, ...incorrectResults];
  }, [
    labels,
    question.correctAnswerIds,
    question.creatorQuestionInfo?.materials,
    question.questionDetails,
    solutionMap,
  ]);
}
