import { useCallback } from 'react';
import { Box } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import IaDisplayTableNumberCell from '@lib/ia/src/layouts/DisplayTableLayout/components/IaDisplayTableCell/IaDisplayTableNumberCell';

import { DisplayTableLayoutCell, DisplayTableLayoutRow } from '../../types';

import IaDisplayTableAutoCompleteCell from './IaDisplayTableAutoCompleteCell';
import IaDisplayTableAvatarGroupCell from './IaDisplayTableAvatarGroupCell';
import IaDisplayTableAvatarTagCell from './IaDisplayTableAvatarTagCell';
import IaDisplayTableAvatarTextCell from './IaDisplayTableAvatarTextCell';
import IaDisplayTableButtonCell from './IaDisplayTableButtonCell';
import IaDisplayTableCustomCell from './IaDisplayTableCustomCell';
import IaDisplayTableEmptyCell from './IaDisplayTableEmptyCell';
import IaDisplayTableEmptyTextCell from './IaDisplayTableEmptyTextCell';
import IaDisplayTableIconCell from './IaDisplayTableIconCell';
import IaDisplayTableIconTextCell from './IaDisplayTableIconTextCell';
import IaDisplayTableIndicatorCell from './IaDisplayTableIndicatorCell';
import IaDisplayTableSelectCell from './IaDisplayTableSelectCell';
import IaDisplayTableTagCell from './IaDisplayTableTagCell';
import IaDisplayTableTextCell from './IaDisplayTableTextCell';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
  },
  rootClickable: {
    '@media (hover: hover)': {
      '&:hover': {
        borderRadius: '4px',
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.1),
        cursor: 'pointer',
      },
    },
  },
};

type IaDisplayTableCellProps = {
  row: DisplayTableLayoutRow;
  cell: DisplayTableLayoutCell;
  isChild?: boolean;
};

function IaDisplayTableCellContent({
  row,
  cell,
  isChild,
}: IaDisplayTableCellProps) {
  if (!cell) return <IaDisplayTableEmptyCell />;

  switch (cell.type) {
    case 'text':
      return <IaDisplayTableTextCell row={row} cell={cell} />;
    case 'icon':
      return <IaDisplayTableIconCell {...cell} />;
    case 'number':
      return <IaDisplayTableNumberCell row={row} cell={cell} />;
    case 'iconText':
      return <IaDisplayTableIconTextCell {...cell} isChild={isChild} />;
    case 'indicator':
      return <IaDisplayTableIndicatorCell {...cell} />;
    case 'avatarText':
      return <IaDisplayTableAvatarTextCell {...cell} />;
    case 'avatarGroup':
      return <IaDisplayTableAvatarGroupCell {...cell} />;
    case 'tag':
      return <IaDisplayTableTagCell {...cell} />;
    case 'avatarTag':
      return <IaDisplayTableAvatarTagCell {...cell} />;
    case 'select':
      return <IaDisplayTableSelectCell row={row} cell={cell} />;
    case 'autoComplete':
      return <IaDisplayTableAutoCompleteCell row={row} cell={cell} />;
    case 'button':
      return <IaDisplayTableButtonCell {...cell} row={row} />;
    case 'empty':
      return <IaDisplayTableEmptyCell />;
    case 'emptyText':
      return <IaDisplayTableEmptyTextCell />;
    case 'custom':
      return <IaDisplayTableCustomCell row={row} cell={cell} />;
    default:
      console.warn('Unknown body cell type:', cell);
      return null;
  }
}

export default function IaDisplayTableCell({
  row,
  cell,
  isChild,
}: IaDisplayTableCellProps) {
  const { getIaAction } = useIaAction();
  const handleClick = useCallback(() => {
    getIaAction<DisplayTableLayoutCell>(cell.clickAction)?.action(cell);
  }, [cell, getIaAction]);

  return (
    <Box
      sx={[styles.root, !!cell.clickAction && styles.rootClickable]}
      onClick={handleClick}
    >
      <IaDisplayTableCellContent row={row} cell={cell} isChild={isChild} />
    </Box>
  );
}
