export const enum ClubViewSlug {
  CardCenterDefault = 'card_center_club_default',
  CardCenterQBank = 'card_center_club_qbank',
  IconRhsDefault = 'icon_rhs_club_default',
  IconRhsCreatorQuestionDuplicate = 'icon_rhs_club_creator_question_dup_to',
  ProfileCenterDefault = 'profile_center_club_default',
  IconRhsClubList = 'icon_rhs_club_list',
  IconRhsAdded = 'icon_rhs_club_added',
}

export const enum SimilarClubViewSlug {
  CardCenterDefault = 'card_center_club_similar_default',
  IconLhsDefault = 'icon_lhs_club_similar_default',
}

export const enum ClubType {
  Unknown = 0,
  PublicExamSection = 1,
}

export const enum ClubCreatorType {
  Unknown = 0,
  Organization = 1,
  User = 2,
}

export const enum ClubMemberType {
  Unknown = 0,
  Member = 1,
  Admin = 2,
}

export const enum ClubAppType {
  Unknown = 0,
  PublicExamSection = 1,
  UserCreateExamSection = 2,
}

export const enum ClubCategoryType {
  Unknown = 0,
  Education = 1,
}

export const enum ClubStatus {
  Unknown = 0,
  Active = 1,
  Inactive = -1,
  ComingSoon = 2,
  Beta = 3,
  Deleted = -4,
}

export const enum ClubJoinedStatus {
  NotJoined = 0,
  Joined = 1,
  RequestedPending = 2,
  ManagerInvitedPending = 3,
}

export const enum ClubPrivacy {
  Public = 1,
  Private = 2,
}

export const enum ClubVisibility {
  Listed = 1,
  Unlisted = 2,
}

export const enum ClubMemberRole {
  Unknown = 0,
  Member = 1,
  Editor = 2,
  Admin = 3,
}

export const enum ClubJoinRequestType {
  Unknown = 0,
  Join = 1,
}

export const enum ClubJoinRequestStatus {
  Unknown = 0,
  UserSent = 1,
  ManagerAccepted = 2,
  UserCanceled = -1,
  ManagerRejected = -2,
}

export const enum ClubAnswerFormatPreferenceStatus {
  Enabled = 1,
  Disabled = -1,
}

export const enum ClubBotType {
  Unknown = 0,
  Rubric = 1,
  Grading = 2,
  Club = 3,
}

export const enum ClubOfficialType {
  Official = 1,
  NonOfficial = -1,
}
