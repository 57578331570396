import { MouseEvent } from 'react';
import { AnswerFormatType, Difficulty, ExamMode } from '@lib/web/apis';

export const BLANK_OPTION_VALUE = 'blank';

export type PracticeQuestionContextValue = {
  id: string;
  question: string;
  tpq: number;
  setId: number;
  details: GetQuizQuestionDetailRes[];
  answerFormatType: AnswerFormatType;
  creatorQuestionInfo?: CreatorQuestionInfo;
  creatorQuestionGroups?: CreatorQuestionGroup[];
  creatorId: string;
  isDynamicDifficulty: boolean;
  staticDifficulty: Difficulty | null;
  dynamicDifficulty: Difficulty | null;
};

export type PracticeQuestionResultContextValue = {
  isCorrect: boolean;
  isOvertime: boolean;
  correctAnswerValues: string[];
  correctAnswerIds: string[];
  statistic?: GetQuizQuestionOptionStatisticRes[];
};

export type PracticeQuestionStatusContextValue = {
  isPendingSubmit: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  isSkipped: boolean;
  startAt: number | null;
  timeSpent: number;
};

export type PracticeQuestionAnswerContextValue = {
  answers: string[];
  eliminatedAnswers: string[];
};

export type PracticeFlowEventContextValue = {
  onNextQuestion: (event?: MouseEvent) => void;
  onPreviousQuestion: (event?: MouseEvent) => void;
  onSubmitQuestion: () => void;
  onLazySubmitQuestion: () => void;
  onCancelSubmitQuestion: () => void;
  onResumeQuestion: () => void;
  onPauseQuestion: () => void;
  onQuizResult?: (event?: MouseEvent) => void;
};

export type PracticeQuestionEventContextValue = {
  onChangeAnswers: (answers: string[]) => void;
  onChangeElimination: (answers: string[]) => void;
};

export type PracticeStatusContextValue = {
  isPaused: boolean;
  isFinished: boolean;
  questionCount: number;
  questionIndex: number;
  isQuestion: boolean;
};

export type PracticeStaticDataContextValue = {
  section: GetExamSectionRes;
  mappedMaterials: Record<string, GetQuizMaterialRes>;
  panelKeys: {
    solution: string;
  };
  preference?: GetCreatorQuestionPreferenceRes;
  mode: ExamMode;
};

export type PracticeLayoutContextValue = {
  showKeyboard: boolean;
  redirectPage?: string | null;
};
