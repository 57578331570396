import { useCallback } from 'react';
import { nonNullable, useDateFormat } from '@front/helper';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { uniq } from 'lodash';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

import { useNotificationMessageContent } from '../message/useNotificationMessageContent';
import { useRenderThreadMessageToHtml } from '../message/useRenderThreadMessageToHtml';

export const useChannelInformation = () => {
  const { displayDateDivider } = useDateFormat();
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();
  const { getNotificationMessageContent } = useNotificationMessageContent();

  const getChannelInformation = useCallback(
    (channel: Channel<StreamChatGenerics>) => {
      const channelData = channel.data;
      const channelState = channel.state;

      const channelCreator = channelData?.created_by?.id;

      // XXX: channel messages only contains the last n messages, be careful to use this
      const messages = channelState.messages.filter((m) => !m.silent);
      const firstMessage = messages[0] as
        | StreamMessage<StreamChatGenerics>
        | undefined;
      const lastMessage = messages[messages.length - 1] as
        | StreamMessage<StreamChatGenerics>
        | undefined;

      // because channel messages only contains the last n messages, we will store firstMessage on channel data
      const firstMessageText =
        renderThreadMessageToHtml(
          channelData?.firstMessageText,
          channelData?.firstMessageCustomBlocks
        ) ||
        renderThreadMessageToHtml(
          firstMessage?.text,
          firstMessage?.customBlocks
        );

      const firstMessageNotificationContent = getNotificationMessageContent({
        messageCreatorId: channelCreator,
        notificationId: firstMessage?.customNotificationId,
      });

      const firstMessageCreatedAt =
        channelData?.firstMessageCreatedAt ||
        streamDateToString(firstMessage?.created_at);

      const lastMessageCreator = lastMessage?.user?.id;
      const lastMessageCreatedAt = streamDateToString(lastMessage?.created_at);
      const lastMessageText = renderThreadMessageToHtml(
        lastMessage?.text,
        lastMessage?.customBlocks
      );
      const lastMessageNotificationContent = getNotificationMessageContent({
        messageCreatorId: lastMessageCreator,
        notificationId: lastMessage?.customNotificationId,
      });

      const channelMemberIds = Object.keys(channel.state.members);
      const channelUserIds = channelMemberIds.filter(
        (id) => !id.startsWith('agent_')
      );
      const channelEngagedMemberIds =
        channelData?.customActiveMembers !== undefined
          ? channelData.customActiveMembers
          : uniq(messages.map((m) => m.user?.id).filter(nonNullable));

      const replies =
        channelData?.customReplyCount !== undefined
          ? channelData.customReplyCount
          : messages.length - 1;

      const dateDividerTime = displayDateDivider(firstMessageCreatedAt);

      const isAgentMessage = !!lastMessage?.user?.id.startsWith('agent_');
      const lastAgentMessage = `
        <span
          data-render-ui="agentMessageContent"
          data-metadata-content="${lastMessageText}"
          data-metadata-agent-composing-status="${lastMessage?.agentComposingStatus}"
          data-metadata-agent-material-process-status="${lastMessage?.agentMaterialProcessStatus}"
          data-metadata-agent-material-file-name="${lastMessage?.agentMaterialFileName}"
          data-metadata-show-typing-effect=${lastMessage?.showTypingEffect}
        />`;

      return {
        channelCreator,
        firstMessageText:
          firstMessageText || firstMessageNotificationContent.text,
        firstMessageCreatedAt,
        lastMessageCreator,
        lastMessageCreatedAt,
        lastMessageIsAgent: isAgentMessage,
        lastMessageText: isAgentMessage
          ? lastAgentMessage
          : lastMessageText || lastMessageNotificationContent.text,
        channelMemberIds,
        channelUserIds,
        channelEngagedMemberIds,
        replies,
        dateDividerTime,
      };
    },
    [
      displayDateDivider,
      getNotificationMessageContent,
      renderThreadMessageToHtml,
    ]
  );

  return {
    getChannelInformation,
  };
};
