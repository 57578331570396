import React, { ReactNode } from 'react';
import ButtonBase, {
  ButtonBaseProps,
  ButtonBaseTypeMap,
} from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';

import LoadingIcon from '../../../components/LoadingIcon';

import { ButtonColorType, ButtonSizeType, ButtonVariantType } from './types';
import {
  getDefaultStyle,
  getHoverStyle,
  getIconSize,
  getLoadingStyle,
  getSize,
  getTheme,
} from './utils';

export type ButtonProps<
  D extends React.ElementType = ButtonBaseTypeMap['defaultComponent'],
  P = {
    color?: ButtonColorType;
    variant?: ButtonVariantType;
    loading?: boolean;
    loadingText?: string;
    suffixIcon?: ReactNode;
    prefixIcon?: ReactNode;
    icon?: boolean;
    size?: ButtonSizeType;
    component?: D;
  }
> = ButtonBaseProps<D, P>;

const styles = {
  root: {
    position: 'relative',
    minHeight: 36,
    '.base-layout-action &': {
      minHeight: { xs: 42, md: 36 },
    },
    typography: 'button',
    fontWeight: 700,
    px: 2.5,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 0.5,
    border: '3px solid',
    borderRadius: 1,
    transitionDuration: '0.2s',
    transitionProperty: 'transform, color',
    '& svg': {
      display: 'block',
      width: 16,
      height: 16,
    },

    '&.is-disabled': {
      opacity: 0.5,
    },
  },
};

function ButtonInner<D extends React.ElementType>(
  {
    sx,
    children,
    icon = false,
    color = 'default',
    variant = 'filled',
    size,
    disabled,
    loading,
    loadingText,
    prefixIcon,
    suffixIcon,
    ...rest
  }: ButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const { palette } = useTheme();
  const buttonTheme = getTheme(palette, color, variant);
  const defaultStyle = getDefaultStyle(buttonTheme);
  const hoverStyle = disabled || loading ? false : getHoverStyle(buttonTheme);
  const loadingStyle = loading ? getLoadingStyle(buttonTheme) : false;
  const sizeStyle = icon ? getIconSize(size) : getSize(size);

  return (
    <ButtonBase
      ref={ref}
      sx={[
        styles.root,
        defaultStyle,
        hoverStyle,
        loadingStyle,
        sizeStyle,
        ...sxProps,
      ]}
      className={disabled && !loading ? 'is-disabled' : ''}
      disableTouchRipple
      disableRipple
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <>
          <LoadingIcon />
          {loadingText}
        </>
      ) : (
        <>
          {!!prefixIcon && prefixIcon}
          {children}
          {!!suffixIcon && suffixIcon}
        </>
      )}
    </ButtonBase>
  );
}

const Button = React.forwardRef(ButtonInner) as <
  D extends React.ElementType = 'button'
>(
  props: ButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof ButtonInner<D>>;

export default Button;
