import useSWR from 'swr';
import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import {
  BookmarkQuizViewSlug,
  ChallengeQuizViewSlug,
  CreatorQuizViewSlug,
  EmojiQuizViewSlug,
  ExamMode,
  FollowingQuizViewSlug,
  HashtagQuizViewSlug,
  HistoryQuizViewSlug,
  QuizGroupType,
} from '@lib/web/apis';
import { format } from 'date-fns';

import apis from '../../apis';
import { ViewSlugInfiniteHookResponse } from '../../types/base/view';
import { PlaylistViewSlug } from '../../types/enums/playlist';

import generateHook, {
  generateInfiniteHook,
  SwrHelperParams,
} from './swr.helper';

const getCustomDateString = (date: Date | string) => {
  return typeof date === 'string' ? date : format(date, 'yyyy-MM-dd');
};
export const useQuizScore = (quizId: string, roundNo: number) =>
  generateHook<GetQuizScoreRes>(
    quizId && roundNo ? `v2/quiz/${quizId}/${roundNo}/score` : null,
    {
      auth: true,
    }
  );

export const useQuizNextRoundNo = (quizId: string) =>
  generateHook<number>(quizId ? `v2/quiz/${quizId}/round-no/next` : null, {
    auth: true,
  });

const getQuizTagsApiKey = (quizId: string, roundNo: number) => {
  return `/v2/quiz/${quizId}/tags/me?roundNo=${roundNo}`;
};
export const useQuizTags = (quizId: string | undefined, roundNo: number) =>
  generateHook<GetQuizTagRes>(
    quizId && roundNo ? getQuizTagsApiKey(quizId, roundNo) : null,
    {
      auth: true,
      immutable: true,
      paginate: true,
    }
  );

export const getQuizResultSummaryKey = (
  quizId?: NullableString,
  roundNo?: NullableNumber,
  dynamicType?: QuizGroupType,
  sectionId?: NullableString,
  sourceId?: NullableString,
  isGetLastGlobalResult?: boolean,
  customDate?: string | Date
) => {
  if (typeof roundNo !== 'number') return null;

  const lastResult =
    isGetLastGlobalResult === undefined
      ? 'false'
      : String(isGetLastGlobalResult);

  const params = new URLSearchParams();

  if (customDate) {
    params.set('customDate', getCustomDateString(customDate));
  }
  if (dynamicType) {
    if (dynamicType) params.set('type', dynamicType.toString());
    if (sectionId) params.set('sectionId', sectionId);
    if (sourceId) params.set('sourceId', sourceId);

    if (roundNo) {
      params.set('isGetLastGlobalResult', lastResult);
    }

    const invalidDynamicType = // only emoji and hashtags need source id
      (dynamicType === QuizGroupType.Emoji ||
        dynamicType === QuizGroupType.Hashtags) &&
      !sourceId;

    return !sectionId || invalidDynamicType
      ? null
      : `v2/quiz/dynamic/result/summary?${params.toString()}`;
  }

  if (!quizId) return null;

  params.set('isGetLastGlobalResult', lastResult);

  return `v2/quiz/${quizId}/${roundNo}/result/summary?${params.toString()}`;
};

export const useQuizResultSummary = ({
  quizId,
  roundNo = 0,
  dynamicType,
  sectionId,
  sourceId,
  isGetLastGlobalResult,
  customDate,
}: {
  quizId?: NullableString;
  roundNo?: NullableNumber;
  dynamicType?: QuizGroupType;
  sectionId?: NullableString;
  sourceId?: NullableString;
  isGetLastGlobalResult?: boolean;
  customDate?: string | Date;
}) => {
  return generateHook<GetQuizResultSummaryRes>(
    getQuizResultSummaryKey(
      quizId,
      roundNo,
      dynamicType,
      sectionId,
      sourceId,
      isGetLastGlobalResult,
      customDate
    ),
    {
      auth: true,
      revalidateOnFocus: false,
    }
  );
};

export const useQuizResultSummaryMultiple = ({
  quizIds = [],
  roundNo = 0,
}: {
  quizIds?: string[];
  roundNo?: number;
}) => {
  const keys = quizIds.sort();
  return useSWR<Record<string, GetQuizResultSummaryRes | null> | null>(
    keys.length > 0 ? keys.join(',') : null,
    {
      fetcher: async () => {
        const result: Record<string, GetQuizResultSummaryRes | null> = {};
        const res = await Promise.all([
          ...quizIds.map((quizId) =>
            apis.quiz.getResultSummary({ quizId, roundNo }).catch(() => null)
          ),
        ]);

        res.forEach((r, index) => {
          result[quizIds[index]] = r === null ? r : r.data.data;
        });

        return result;
      },
    }
  );
};

export const useQuizOneRoundResult = ({
  quizId,
  roundNo,
}: {
  quizId: string;
  roundNo: number;
}) =>
  generateHook<GetQuizResultRes>(
    quizId && roundNo ? `v2/quiz/${quizId}/${roundNo}/result/one-round` : null,
    { paginate: true }
  );

const getQuizHistoryListApiKey = (
  sectionId: NullableString,
  mode: ExamMode,
  customDate?: Date | string,
  page = 1,
  pageSize = 10
) => {
  if (!sectionId) return undefined;

  const params = new URLSearchParams();

  params.set('sectionId', sectionId);
  params.set('mode', `${mode}`);
  params.set('page', `${page}`);
  params.set('limit', `${pageSize}`);
  if (customDate) {
    params.set('customDate', getCustomDateString(customDate));
  }

  return `v2/quiz/practice/history/list?${params.toString()}`;
};

export const useQuizHistoryAllList = ({
  sectionId,
  mode,
  customDate,
  pageSize = 10,
}: {
  sectionId: NullableString;
  mode?: ExamMode;
  pageSize?: number;
  customDate?: Date | string;
}) =>
  generateInfiniteHook<GetMemberQuizRes>(
    (index) =>
      getQuizHistoryListApiKey(
        sectionId,
        mode || ExamMode.Unknown,
        customDate,
        index + 1,
        pageSize
      ),
    pageSize,
    {
      auth: true,
    }
  );

const getQuizCreatorListApiKey = (
  sectionId: NullableString,
  mode: ExamMode,
  page: number,
  pageSize: number
) => {
  if (!sectionId) return undefined;

  return `v2/quiz/creator-question/list?sectionId=${sectionId}&mode=${mode}&page=${page}&limit=${pageSize}`;
};

export const useQuizCreatorAllList = ({
  sectionId,
  mode,
  pageSize = 10,
}: {
  sectionId: NullableString;
  mode?: ExamMode;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetMemberQuizRes>(
    (index) =>
      getQuizCreatorListApiKey(
        sectionId,
        mode || ExamMode.Unknown,
        index + 1,
        pageSize
      ),
    pageSize,
    {
      auth: true,
      revalidateAll: true,
    }
  );

const getQuizBookmarkListApiKey = (
  sectionId: NullableString,
  mode: ExamMode | null,
  page: number,
  pageSize: number
) => {
  if (!sectionId) return undefined;

  return `v2/quiz/mark/bookmark/list?sectionId=${sectionId}&page=${page}&limit=${pageSize}${
    mode ? `&mode=${mode}` : ''
  }`;
};

export const useQuizAllBookmarkList = ({
  sectionId,
  mode,
  pageSize = 10,
}: {
  sectionId: NullableString;
  mode?: ExamMode;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetMemberQuizRes>(
    (index) =>
      getQuizBookmarkListApiKey(sectionId, mode || null, index + 1, pageSize),
    pageSize,
    {
      auth: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

const getQuizGroupListApiKey = (
  sectionId: NullableString,
  type: QuizGroupType,
  customDate?: Date | string,
  page = 1,
  pageSize = 10
) => {
  if (!sectionId) return undefined;

  const params = new URLSearchParams();

  params.set('sectionId', sectionId);
  params.set('type', `${type}`);
  params.set('page', `${page}`);
  params.set('limit', `${pageSize}`);
  if (customDate) {
    params.set('customDate', getCustomDateString(customDate));
  }
  return `v2/quiz/question/mark/group?${params.toString()}`;
};

export const useQuizAllGroupList = ({
  sectionId,
  type,
  customDate,
  pageSize = 10,
}: {
  sectionId: NullableString;
  type: QuizGroupType;
  customDate?: Date | string;
  pageSize?: number;
}) =>
  generateInfiniteHook<GetMemberGroupQuizRes>(
    (index) =>
      getQuizGroupListApiKey(sectionId, type, customDate, index + 1, pageSize),
    pageSize,
    {
      auth: true,

      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

const getQuizHashtagGroupListApiKey = (
  sectionId: NullableString,
  page: number,
  pageSize: number
) => {
  if (!sectionId) return undefined;

  return `v2/quiz/question/hashtag/group?sectionId=${sectionId}&page=${page}&limit=${pageSize}`;
};

export const useQuizHashtagGroupList = (
  sectionId: NullableString,
  pageSize = 10
) =>
  generateInfiniteHook<GetMemberHashtagsGroupQuizRes>(
    (index) => getQuizHashtagGroupListApiKey(sectionId, index + 1, pageSize),
    pageSize,
    {
      auth: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export const useQuizBookmark = (quizId?: NullableString) =>
  generateHook<GetQuizBookmarkRes | null>(
    quizId ? `v2/quiz/mark/bookmark?quizId=${quizId}` : null,
    {
      auth: true,
    }
  );

const getFriendsHistoryQuizListApiKey = (
  sectionId: NullableString,
  mode: ExamMode | null,
  userId: NullableString,
  page: number,
  pageSize: number
) => {
  if (!sectionId) return undefined;

  const params = new URLSearchParams();
  params.set('sectionId', sectionId);

  if (mode) params.set('mode', mode.toString());
  if (userId) params.set('userId', userId);

  params.set('page', page.toString());
  params.set('limit', pageSize.toString());

  return `/v2/quiz/practice/history/friend/list?${params.toString()}`;
};

export const useFriendsHistoryQuizList = (
  sectionId: NullableString,
  mode: ExamMode | null,
  userId: NullableString,
  pageSize = 10
) =>
  generateInfiniteHook<GetFriendsHistoryQuizRes>(
    (index) =>
      getFriendsHistoryQuizListApiKey(
        sectionId,
        mode,
        userId,
        index + 1,
        pageSize
      ),
    pageSize,
    {
      auth: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

export const useQuizQuestionNote = (quizQuestionId?: NullableString) =>
  generateHook<GetQuizQuestionNoteRes>(
    quizQuestionId ? `/v2/quiz/question/note/${quizQuestionId}` : null,
    {
      auth: true,
      immutable: true,
    }
  );

const getQuizApiKey = (quizId: string, roundNo: number, path: string) => {
  return `v2/quiz/${quizId}/${roundNo}${path}`;
};

export const useQuizResultTypical = (
  quizId: NullableString,
  roundNo: NullableNumber
) =>
  generateHook<GetQuizResultTypicalRes>(
    quizId && roundNo
      ? getQuizApiKey(quizId, roundNo, '/result/typical')
      : null,
    {
      auth: true,
    }
  );

const getQuizPracticeReportApiKey = (
  quizId: NullableString | undefined,
  roundNo: NullableNumber
) => {
  if (!quizId || !roundNo) return null;

  const urlParam = new URLSearchParams();
  urlParam.set('quizId', quizId);
  urlParam.set('roundNo', `${roundNo}`);

  return `v2/quiz/practice/report?${urlParam.toString()}`;
};

export const useQuizPracticeReport = (
  quizId: NullableString | undefined,
  roundNo: NullableNumber
) =>
  generateHook<GetQuizResultReportRes>(
    getQuizPracticeReportApiKey(quizId, roundNo),
    {
      auth: true,
    }
  );

export const useQuizInfoSummary = (
  quizId?: NullableString,
  roundNo: NullableNumber = 0
) =>
  generateHook<GetQuizResultInfoSummaryRes>(
    quizId && typeof roundNo === 'number'
      ? getQuizApiKey(quizId, roundNo, '/info/summary')
      : null,
    {
      auth: true,
    }
  );

const getQuizHashtagsKey = (
  quizId?: NullableString,
  dynamicType?: QuizGroupType,
  sectionId?: NullableString,
  sourceId?: string
) => {
  if (dynamicType) {
    if (!sectionId || !sourceId) return null;

    const urlParam = new URLSearchParams();
    urlParam.set('type', dynamicType.toString());
    urlParam.set('sectionId', sectionId);
    if (sourceId) urlParam.set('sourceId', sourceId);

    return `v2/quiz/dynamic/hashtags?${urlParam.toString()}`;
  }

  if (!quizId) return null;
  return `v2/quiz/${quizId}/hashtags`;
};
export const useQuizHashtags = ({
  quizId,
  sectionId,
  dynamicType,
  sourceId,
}: {
  quizId?: NullableString;
  sectionId?: NullableString;
  dynamicType?: QuizGroupType;
  sourceId?: string;
}) =>
  generateHook<GetQuizHashtagsRes[]>(
    getQuizHashtagsKey(quizId, dynamicType, sectionId, sourceId),
    {
      auth: true,
    }
  );

const getQuizDynamicQuestionListApiKey = ({
  sectionId,
  dynamicType,
  sourceId = '',
  page,
  pageSize,
  isFilterMistake,
  isFilterOvertime,
  isFilterEmoji,

  customDate,
}: {
  sectionId: NullableString;
  dynamicType: QuizGroupType | null;
  sourceId?: string;
  page: number;
  pageSize: number;
  customDate?: string | Date;
  isFilterMistake?: boolean;
  isFilterOvertime?: boolean;
  isFilterEmoji?: boolean;
}) => {
  if (!sectionId || !dynamicType || !sourceId) return undefined;

  const params = new URLSearchParams();
  params.set('sectionId', sectionId);
  params.set('type', `${dynamicType}`);
  params.set('page', `${page}`);
  params.set('limit', `${pageSize}`);
  if (customDate) {
    params.set('customDate', getCustomDateString(customDate));
  }
  if (sourceId) params.set('sourceId', sourceId);
  if (isFilterMistake) params.set('isFilterMistake', `${isFilterMistake}`);
  if (isFilterOvertime) params.set('isFilterOvertime', `${isFilterOvertime}`);
  if (isFilterEmoji) params.set('isFilterEmoji', `${isFilterEmoji}`);

  return `v2/quiz/dynamic/question/summary?${params.toString()}`;
};

export const useQuizDynamicQuestionList = ({
  sectionId = null,
  dynamicType = null,
  pageSize = 10,
  sourceId = '',
  isFilterMistake,
  isFilterOvertime,
  isFilterEmoji,
  customDate,
}: {
  sectionId?: NullableString;
  dynamicType: QuizGroupType | null;
  pageSize?: number;
  sourceId?: string;
  isFilterMistake?: boolean;
  isFilterOvertime?: boolean;
  isFilterEmoji?: boolean;
  customDate?: string | Date;
}) =>
  generateInfiniteHook<GetQuizQuestionSummaryRes>(
    (index) =>
      getQuizDynamicQuestionListApiKey({
        sectionId,
        dynamicType,
        sourceId,
        page: index + 1,
        pageSize,
        customDate,
        isFilterMistake,
        isFilterOvertime,
        isFilterEmoji,
      }),
    pageSize,
    {
      auth: true,
      immutable: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateAll: true,
    }
  );

export const useQuizQuestion = (quizId: string) =>
  generateHook<GetQuizPracticeRes>(
    quizId ? `v2/quiz/practice/${quizId}` : null,
    {
      auth: true,
    }
  );

export const useQuizQuestionMarkedEmoji = (quizQuestionId?: NullableString) =>
  generateHook<GetQuizQuestionMarkedEmojiRes>(
    quizQuestionId ? `/v2/quiz/question/${quizQuestionId}/mark/emoji` : null,
    {
      auth: true,
      immutable: true,
    }
  );

export const useQuizQuestionMarkedEmojiMultiple = (
  quizQuestionIds: string[]
) => {
  const key =
    quizQuestionIds.length > 0
      ? `useQuizQuestionMarkedEmojiMultiple${quizQuestionIds.toString()}`
      : null;

  // XXX: better to make it generalize in our swr helper
  return useSWR<Record<string, GetQuizQuestionMarkedEmojiRes> | null>(key, {
    fetcher: async () => {
      const res = await Promise.all(
        quizQuestionIds.map((id) => apis.quiz.getEmoji(id))
      );

      const result: Record<string, GetQuizQuestionMarkedEmojiRes> = {};

      res.forEach((r, index) => {
        if (r?.data.data) {
          result[quizQuestionIds[index]] = r.data.data;
        }
      });

      return result;
    },
  });
};

type GetIaPlaylistResMap = {
  [PlaylistViewSlug.AlbumCenterPlaylistDefault]: GetIaAlbumCenterPlaylistDefaultRes;
};

export function useIaPlaylists<
  K = GetIaPlaylistDefaultRes,
  T extends PlaylistViewSlug | undefined = undefined
>(
  query: Omit<IaSearchReq<T>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends PlaylistViewSlug ? GetIaPlaylistResMap[T] : K
  >(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/playlists?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      paginate: true,
      immutable: true,
      auth: true,
    }
  );
}

export function useIaCreatorQuizList(
  query: IaSearchReq<CreatorQuizViewSlug.AlbumCenterIACreatorQuizDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaCreatorQuizAlbumCentreDefaultViewRes>;
export function useIaCreatorQuizList(
  query: Omit<IaSearchReq<CreatorQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaCreatorQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/by-creator?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}

export function useIaEmojiQuizList(
  query: IaSearchReq<EmojiQuizViewSlug.AlbumCenterIAEmojiQuizDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaEmojiQuizAlbumCentreDefaultViewRes>;
export function useIaEmojiQuizList(
  query: Omit<IaSearchReq<EmojiQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaEmojiQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/emoji?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}

export function useIaHashtagQuizList(
  query: IaSearchReq<HashtagQuizViewSlug.AlbumCenterIAHashtagQuizDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaHashtagQuizAlbumCentreDefaultViewRes>;
export function useIaHashtagQuizList(
  query: Omit<IaSearchReq<HashtagQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaHashtagQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/hashtag?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}

export function useIaFollowingQuizList(
  query: IaSearchReq<FollowingQuizViewSlug.AlbumCenterIAFollowingQuizDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaFollowingQuizAlbumCentreDefaultViewRes>;
export function useIaFollowingQuizList(
  query: Omit<IaSearchReq<FollowingQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaFollowingQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/following?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}

export function useIaHistoryQuizList(
  query: IaSearchReq<HistoryQuizViewSlug.AlbumCenterIAHistoryPlaylistDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaHistoryQuizAlbumCentreDefaultViewRes>;
export function useIaHistoryQuizList(
  query: Omit<IaSearchReq<HistoryQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaHistoryQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/history?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}

export function useIaChallengeQuizList(
  query: IaSearchReq<ChallengeQuizViewSlug.AlbumCenterIAChallengePlaylistDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaChallengeQuizAlbumCentreDefaultViewRes>;
export function useIaChallengeQuizList(
  query: Omit<IaSearchReq<ChallengeQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaChallengeQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/challenge?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}

export function useIaBookmarkQuizList(
  query: IaSearchReq<BookmarkQuizViewSlug.AlbumCenterIABookmarkPlaylistDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaSBookmarkQuizAlbumCentreDefaultViewRes>;
export function useIaBookmarkQuizList(
  query: Omit<IaSearchReq<BookmarkQuizViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaSBookmarkQuizAlbumCentreDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/quiz/bookmark?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}
export const useQuizQuestionCount = (params?: GetQuizQuestionCountReq) =>
  generateHook<GetQuizQuestionCountRes>(
    params
      ? ['v2/quiz/available/exam-question/count/map', JSON.stringify(params)]
      : null,
    {
      auth: true,
      data: params,
      method: 'POST',
      /**
       * In CreateQuizFormProvider, we need to know the values before and after changes, so we set keepPreviousData to true.
       * Otherwise, we'll get 'undefined' when fetching a new key, which breaks our condition.
       */
      keepPreviousData: true,
    }
  );
