// eslint-disable-next-line import/prefer-default-export
export const enum QuizPDFStatus {
  Error = -1,
  Unknown = 0,
  Processing = 1,
  Done = 2,
}

export const enum QuizGroupType {
  Emoji = 2,
  Bookmark = 3,
  Mistake = 4,
  Overtime = 5,
  Hashtags = 6,
}

export const enum QuizSourceType {
  Unknown = 0,
  LegacyEditor = 1,
  UserCreate = 2,
}

export const enum QuizType {
  Quiz = 1,
  TestQuiz = 2,
  SingleTagQuiz = 3,
  Challenge = 4,
  UserQuiz = 5,
  CreatorUserQuiz = 6,
  NonChallenge = 10,
}

export const enum QuizStatus {
  Unknown = 0,
  Enabled = 1,
  Disabled = -1,
  Outdated = -2,
}

export const enum PracticeResult {
  Correct = 1,
  Wrong = -1,
}

export enum OvertimeStatus {
  OnTime = 1,
  Overtime = -1,
}

export enum PlaylistType {
  Quiz = 1,
  Challenge = 4,
  UserQuiz = 5,
  CreatorUserQuiz = 6,
}

export const enum PlaylistMode {
  Unknown = 0,
  Practice = 1,
  MockExam = 2,
}

export const enum PlaylistSavedStatus {
  Saved = 1,
  UnSaved = -1,
}

export const enum QuizViewSlug {
  SmallIconsCenterQuizDefault = 'small_icons_center_quiz_default',
  TableCenterQuizDefault = 'table_center_quiz_default',
}

export const enum CreatorQuizViewSlug {
  AlbumCenterIACreatorQuizDefault = 'album_center_ia_creatorquiz_default',
}

export const enum EmojiQuizViewSlug {
  AlbumCenterIAEmojiQuizDefault = 'album_center_ia_emojiquiz_default',
}

export const enum HashtagQuizViewSlug {
  AlbumCenterIAHashtagQuizDefault = 'album_center_ia_hashtagquiz_default',
}

export const enum FollowingQuizViewSlug {
  AlbumCenterIAFollowingQuizDefault = 'album_center_ia_following_quiz_default',
}

export const enum HistoryQuizViewSlug {
  AlbumCenterIAHistoryPlaylistDefault = 'album_center_ia-history-playlist_default',
}

export const enum ChallengeQuizViewSlug {
  AlbumCenterIAChallengePlaylistDefault = 'album_center_ia-challenge-playlist_default',
}

export const enum BookmarkQuizViewSlug {
  AlbumCenterIABookmarkPlaylistDefault = 'album_center_ia-bookmark-playlist_default',
}

export const enum DeductQuizQuotaType {
  QuizQuestionDaily = 1,
  QuizSolutionDaily = 2,
}
